import React, { createContext, useContext, useState } from 'react';

const LinkedInContext = createContext();

export const LinkedInProvider = ({ children }) => {
  const [linkedinURL, setLinkedinURL] = useState('');
  const [formState, setFormState] = useState({
    instagram: '',
    phone: '',
  });

  return (
    <LinkedInContext.Provider value={{ linkedinURL, setLinkedinURL, formState, setFormState }}>
      {children}
    </LinkedInContext.Provider>
  );
};

export const useLinkedIn = () => useContext(LinkedInContext);
