import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css'; // Create this file for additional custom styles if needed
import ycLogo from '../../assets/images/y-yc-logo.png'

const Footer = () => {
  return (
    <footer className="footer py-3">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 text-center text-md-start mb-1 mb-md-0">
            In The Room, Inc. © 2024
            <a href='https://ycombinator.com' target='blank'>
              <img src={ycLogo} alt="Y-Combinator Logo" className="ycLogo" />
            </a>
          </div>
          <div className="col-12 col-md-6 text-center text-md-end">
            {/* <a href='/whitepaper' className="d-inline mx-2 flip-color">White Paper</a> */}
            {/* <a href='/about' className="d-inline mx-2">About</a> */}
            <a href="/terms" className="d-inline mx-2">Terms</a>
            <a href="/privacy-policy" className="d-inline mx-2">Privacy Policy</a>
            <a href="mailto:hello@intheroom.social" onClick={() => window.location = 'mailto:hello@intheroom.social'} className="d-inline mx-2">Contact</a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
