import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Header from '../components/Header';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Meta from '../components/Meta';
import './About.css';

const About = () => {
  const [theme, setTheme] = useState('light');
  const [titleIndex, setTitleIndex] = useState(0);
  const titles = ["Serendipity", "Relatability", "Intentionality", "Authenticity", "Discovery", "Spontaneity"];
  // const iphoneImage = theme === 'dark' ? iphoneDark : iphoneLight;

  useEffect(() => {
    const currentTheme = document.body.getAttribute('data-theme') || 'light';
    setTheme(currentTheme);
  }, []);

  useEffect(() => {
    const themeChangeHandler = () => {
      setTheme(document.body.getAttribute('data-theme'));
    };

    window.addEventListener('themeChange', themeChangeHandler);

    return () => {
      window.removeEventListener('themeChange', themeChangeHandler);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTitleIndex((prevIndex) => (prevIndex + 1) % titles.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [titles.length]);

  return (
    <>
      <Meta
        title="In The Room | About"
        url="https://www.intheroom.social/about"
      />
      <Header />
      <div className="about">
        <section className="about-header">
          <div className="about-header-content col-12 col-md-9 col-lg-5">
            <div className="fade-container mb-0 mb-md-1">
              <TransitionGroup>
                <CSSTransition
                  key={titleIndex}
                  timeout={{ enter: 1000, exit: 1000 }}
                  classNames="fade"
                >
                  <h1 className="display-2">{titles[titleIndex]}</h1>
                </CSSTransition>
              </TransitionGroup>
            </div>
            <p className='mt-0 mt-md-3'>That's In The Room.</p>
            <div className="scroll-down-arrow">
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
        </section>

        <section className="about-section">
          <div className="about-content row">
            <div className="about-text col-12 col-md-6 ps-md-5 mb-5">
              <h2 className="mb-2 display-5">Who is Here?</h2>
              <p className="px-4 px-md-0">View who's "in the room" with you in physical spaces.</p>
            </div>
            <div className="col-12 col-md-6">
              <img src={require(`../assets/images/main-${theme}.png`)} alt="iPhone" className="about-image" />
            </div>
          </div>
        </section>

        <section className="about-section reverse">
          <div className="about-content row">
            <div className="about-text col-12 col-md-6 ps-md-5 mb-5">
              <h2 className="mb-2 display-5">What Do You Do?</h2>
              <p className="px-4 px-md-0">Share and view social media profiles with others in the room.</p>
            </div>
            <div className="col-12 col-md-6">
              <img src={require(`../assets/images/profile-${theme}.png`)} alt="iPhone" className="about-image" />
            </div>
          </div>
        </section>

        <section className="about-section">
          <div className="about-content row">
            <div className="about-text col-12 col-md-6 ps-md-5 mb-5">
              <h2 className="mb-2 display-5">Work The Room</h2>
              <p className="px-4 px-md-0">Connect with people you're interested in meeting.</p>
            </div>
            <div className="col-12 col-md-6">
              <img src={require(`../assets/images/messages-${theme}.png`)} alt="iPhone" className="about-image" />
            </div>
          </div>
        </section>



      </div>
    </>
  );
};

export default About;
