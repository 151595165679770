import React, { useState } from 'react';
import { Container, Form, Button, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useLinkedIn } from '../context/linkedin-context';

const LinkedInSearchPage = () => {
  const { setLinkedinURL } = useLinkedIn();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  const handleSearch = async () => {
    setSearchLoading(true);
    setSearchResults([]);
    try {
      const response = await fetch(
        'https://8tnulw7wii.execute-api.us-east-1.amazonaws.com/production/apify/search-linkedin-profiles',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ keyword: searchTerm }),
        }
      );
      const data = await response.json();
      setSearchResults(data || []);
    } catch {
      setSearchResults([]);
    } finally {
      setSearchLoading(false);
    }
  };

  const handleSelectProfile = (url) => {
    setLinkedinURL(url);
    navigate('/sign-up');
  };

  return (
    <Container className="mt-4 p-4 text-light rounded">
      <div className="mb-5">
        <h1
          className="text-white mb-0"
          style={{ fontSize: '2.3rem', fontWeight: 'bolder', textAlign: 'left' }}
        >Search LinkedIn</h1>
        <p style={{opacity: .6, color: 'white', fontWeight: 'bold', fontSize: '1.2rem'}}>Search your linkedin profile by name </p>
      </div>
      <Form>
        <Form.Group controlId="formSearch" className="mb-4">
          <Form.Label style={style.formLabel}>Search</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="custom-input bg-transparent text-light border-bottom border-0 rounded-0 px-0"
          />
        </Form.Group>
        <Button onClick={handleSearch} disabled={searchLoading}>
          {searchLoading ? 'Searching...' : 'Search'}
        </Button>
      </Form>
      {searchResults.length > 0 && (
        <Dropdown.Menu show className="mt-4 bg-dark text-light border" style={{ width: '90vw' }}>
          {searchResults.map((profile, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => handleSelectProfile(profile.profileURL)}
              className="bg-dark text-light"
            >
              <img
                src={profile.profilePicture}
                alt=""
                className="rounded-circle me-2"
                style={{ width: '40px' }}
              />
              {profile.fullName}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      )}
      {/* {searchResults.map((result, index) => (
        <Dropdown.Item key={index} onClick={() => handleSelectProfile(result.profileURL)}>
          {result.fullName} - {result.headline}
        </Dropdown.Item>
      ))} */}
    </Container>
  );
};

const style = {
  formLabel: {
    fontSize: '1rem',
    color: 'white',
    opacity: 0.7,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
};

export default LinkedInSearchPage;
