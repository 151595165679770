import React from 'react';
import { Helmet } from 'react-helmet-async';

const getFullImageUrl = (relativePath) => {
  const { protocol, hostname, port } = window.location;
  const domain = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
  return `${domain}${relativePath}`;
};

const Meta = ({ title, description, keywords, author, image, url }) => {
  const fullImageUrl = getFullImageUrl(image);
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={author} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <link rel="icon" href="/favicon.ico" />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={fullImageUrl} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={fullImageUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={author} />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: 'Connect with people around you | In The Room',
  description: 'In The Room is an in-person networking and connectivity app for helping people connect in physical spaces. Perfect for networking events, meetups, conferences, festivals, arenas, conference halls, clubs, bars, lounges, and other public places.',
  keywords: 'networking, nearby, local social, app, hyper-local',
  author: 'In The Room, Inc.',
  image: require('../../assets/images/in-the-room-cover.png'),
  url: window.location.href, // Provide your website URL
};

export default Meta;
