import React from 'react';
import Meta from "../components/Meta";
import Header from "../components/Header/Header";
import appImage from '../assets/images/in-the-room-shots.webp'

const WhitePaper = () => {
  return (
    <>
    <Meta
      title="In The Room | The White Paper"
      url="https://www.intheroom.social/whitepaper"
    />
    <div>
        <Header />
        <div className="text-container my-5 px-5 px-md-0">

        <img src={appImage} alt="App Screen Shots" className="app-image mb-4" />
        <h1 className='text-center'><b>The White Paper</b></h1>
        <p className='text-center'><small>André Woodley Jr<br/>June 20, 2024</small></p>

        <p>We have dedicated over a decade to refining our vision of a hyper-local social networking platform that tackles core issues within the social interaction space, both online and offline. This white paper elucidates the motivations behind our pursuit, outlines the current social landscape, and delineates our plans to revolutionize how people connect through our solution, "In The Room."</p>

        <h2>Our Vision</h2>
        <p>We believe a shift is imminent—a new social platform centered on location that integrates our online identities into offline spaces, making connecting easier. This approach aims to foster genuine connections and bridge the gap between our online personas and physical interactions. Here’s why:</p>
        <ul>
            <li><b>Generational Shift:</b> With 70% of Gen Z open to sharing their location compared to just 7% of Millennials, there's a trend toward using technology more transparently to foster online-to-offline social connections.</li>
            <li><b>Global Connectivity:</b> With 3 billion people sharing their lives online, and over 1 billion having professional accounts like LinkedIn, the potential for integrating these identities into physical spaces is immense.</li>
        </ul>

        <h2>The Current Landscape</h2>
        <p>Despite being able to connect with billions of people online, there is a global pandemic of loneliness and depression. Our current social dynamics present several challenges:</p>
        <ul>
            <li><b>Anti-social Culture:</b> Factors such as anxiety, cliquey behavior, and reliance on social media for initial judgments hinder meaningful connections.</li>
            <li><b>Broken Outgoing Experiences:</b> Planning social outings can be frustrating due to poor information, availability, and cumbersome entry processes.</li>
            <li><b>Isolation of Affluence:</b> Affluent individuals often face social isolation, struggling to form authentic connections as they are viewed as opportunities for personal gain.</li>
            <li><b>Global Loneliness:</b> The increase in reported loneliness and depression underscores the need for more meaningful social interactions.</li>
            <li><b>Language Barriers:</b> As globalization increases, language barriers remain a significant hurdle to connecting meaningfully in new environments.</li>
        </ul>

        <h2>The Role of Social Clubs</h2>
        <p>Social clubs like country clubs and fraternities have proven successful in building community bonds. Clubs like Soho House, which integrate dining and nightlife, showcase the potential for diverse social experiences. These models of engagement inspire our approach to creating varied social interactions under one umbrella.</p>

        <h2>Our Journey</h2>
        <p>We have pursued the idea of "In The Room" and other hyper-local social networking solutions for over ten years, releasing iterations of "In The Room" since 2014. Despite believing that big companies would adopt this concept, the reality is that these companies focus on advertisements and keeping users inside the app. Our persistence stems from a strong belief that this idea is more than just a feature or one-off app; it has the potential to grow to the level of Meta or Google.</p>

        <h2>In The Room: A Proposed Solution</h2>
        <p>"In The Room" is our solution to the aforementioned social challenges, designed to enhance real-world connections through a hyper-local approach. We built "In The Room," a hyper-local app that helps people connect in physical spaces. It's perfect for networking events, meetups, conferences, festivals, arenas, conference halls, clubs, bars, lounges, and other public spaces. Key features include:</p>
        <ul>
            <li><b>Seamless Integration:</b> Users can discover events, join experiences, check in smoothly, meet new people easily, and manage interactions all within one platform.</li>
            <li><b>Localized Focus:</b> Initially focusing on providing a seamless experience for specific communities to refine our offerings and ensure effectiveness before expanding.</li>
            <li><b>Real-Time Connection:</b> "In The Room" leverages real-time data to help people connect with others in the same physical space, making it easier to meet new people at events, meetups, conferences, festivals, arenas, clubs, bars, lounges, and other public spaces.</li>
        </ul>

        <h2>Conclusion</h2>
        <p>"In The Room" represents our attempt to connect the world by creating a platform that enhances real-world connections in an increasingly digital age. Our vision is to foster genuine connections, starting with a core community and scaling to broader markets, ultimately bridging the gap between our online identities and physical interactions.</p>

        <p>This is more than just an app; it's a movement to improve the way people connect and interact in the real world. We believe in its potential to foster genuine connections and make a significant impact on how we engage socially in the digital era.</p>
    </div>
    </div>
    </>
  );
}

export default WhitePaper;
