import React, { useState, useEffect } from 'react';
import Meta from '../../components/Meta';
import Header from '../../components/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Partner.css';
import { Accordion } from 'react-bootstrap';

const Partner = () => {
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    const currentTheme = document.body.getAttribute('data-theme') || 'light';
    setTheme(currentTheme);
  }, []);

  useEffect(() => {
    const themeChangeHandler = () => {
      setTheme(document.body.getAttribute('data-theme'));
    };

    window.addEventListener('themeChange', themeChangeHandler);

    return () => {
      window.removeEventListener('themeChange', themeChangeHandler);
    };
  }, []);

  const [numEvents, setNumEvents] = useState(12);
  const [avgAttendees, setAvgAttendees] = useState(100);

  const handleNumEventsChange = (e) => {
    setNumEvents(parseInt(e.target.value, 10));
  };

  const handleAvgAttendeesChange = (e) => {
    setAvgAttendees(parseInt(e.target.value, 10));
  };

  const calculateRevenue = () => {
    const revenuePerEvent = avgAttendees * 0.45 * 20 * 12 * 0.7 * 0.6;
    return numEvents * revenuePerEvent;
  };

  const formatRevenue = (revenue) => {
    return revenue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <>
      <Meta
        title="Partner with Us - In The Room"
        description="Learn why partnering with In The Room can enhance your events and increase your revenue. Discover our integrations and premium features."
      />
      <Header />
      <div className={`partner-container ${theme}`}>
        <section className='header-section'>
          <h2 className='display-5 mb-2'>...if you believe...</h2>
          <div className='mb-0 text-center row flex-lg-row'>
            <div className='col'>
              <span className='display-1'>a</span>
              <br/>
              <span>Attendees</span>
            </div>
            <div className='col-1 col-md'>
              <span className='display-1'>+</span>
              <br/>
              <span></span>
            </div>
            <div className='col'>
              <span className='display-1'>e</span>
              <br/>
              <span>Engagment</span>
            </div>
            <div className='col-1 col-md'>
              <span className='display-1'>=</span>
              <br/>
              <span></span>
            </div>
            <div className='col'>
              <span className='display-1'>s</span>
              <br/>
              <span>Success</span>
            </div>
          </div>
          <h5 className='display-5 mt-3 text-center'>we'd love to partner</h5>
        </section>

        <section className="quote-section">
          <h2 className='display-5 text-center w-75' style={{fontWeight: '800'}}>You gather like-minded people,<br/>In The Room connects them</h2>
        </section>

        <section className="statement-section">
          <div className='col-12'>
            <p className='display-6 text-left'> "In The Room," helps people connect in physical spaces. It's perfect for events and venues where people gather.</p>
            <hr className='hr' />
          </div>
          <div className='row'>
            <div className='col-12 col-md-4 mb-4'>
              <h4>Know Who is Here</h4>
              <p>Attendees can see who's "in the room" with them at events or venues.</p>
            </div>
            <div className='col-12 col-md-4 mb-4'>
              <h4>Connect With Others</h4>
              <p>Attendees can view others' social media profiles, share their own, and let connections flow naturally.</p>
            </div>
            <div className='col-12 col-md-4 mb-4'>
              <h4>Work The Room</h4>
              <p>Attendees can message and connect with people they are interested in meeting and talking to.</p>
            </div>
          </div>
        </section>

        <section className="integrations-section mb-5 mb-md-2">
          <div className="row align-items-center">
            <div className="col-12 col-md-7 text-center text-md-start">
              <p className='display-4 font-weight-bold neon-text mb-2'>We work with <strong>150+ event platforms</strong></p>
              <span className='bg-black p-2'>No operational changes required</span>
            </div>
            <div className="col-12 col-md-5">
            </div>
          </div>
        </section>

        <section className="integration-steps">
          <div className="row align-items-center">
          <div className="col-12 col-md-5 text-center text-md-start px-3">
            <h3 className='display-6' style={{fontWeight: '600'}}>One Step to Connect</h3>
            <p className="text-md-left mb-1" style={{ fontSize: '1.5rem'}}>We directly integrate with whatever tools you are using and automate the following:</p>
          </div>
          <div className="col-12 col-md-7">
            <Accordion defaultActiveKey="0" className="mt-5" style={{width:'100%'}}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>1. Detect your new events and opportunities.</Accordion.Header>
                <Accordion.Body>
                Every room on "In The Room" represents a physical location. By connecting "In The Room" to your preferred management tool, we can detect new opportunities and create rooms for you automatically.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>2. Welcome your attendees and prompt them to sign up</Accordion.Header>
                <Accordion.Body>
                When an attendee registers for your event, we automatically prompt them to sign up via SMS and/or email and provide follow-ups.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>3. Track and automate your earnings effortlessly</Accordion.Header>
                <Accordion.Body>
                All revenue is tracked in a dashboard and payments are automated.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          </div>
        </section>

        <section className="revenue-section">
          <div className="row align-items-center">
            <div className='col-12 col-md-6 px-3'>
              <h3 className='display-6' style={{fontWeight: '600'}}>Generate More Revenue</h3>
              <p className="text-md-left mb-1" style={{ fontSize: '1.5rem'}}>We split 60% of revenue with you when your attendees join and make a purchase</p>
              <p className='text-secondary'>*In The Room is a free product with premium features</p>
            </div>
            <div className="revenue-calculator px-4 rounded-corners col-11 col-md-6 mt-3 mt-md-0 m-auto">
              <h2 className='mb-0 display-6 mt-2 text-white'>Potential Revenue</h2>
              <p className='text-white'>Calculate how much additional revenue you could generate:</p>
              <div className="calculator-form">
                <div className="form-group">
                  <label htmlFor="numEvents">Number of Events:</label>
                  <input
                    type="number"
                    id="numEvents"
                    className="form-control"
                    value={numEvents}
                    onChange={handleNumEventsChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="avgAttendees">Average Number of Attendees per Event:</label>
                  <input
                    type="number"
                    id="avgAttendees"
                    className="form-control"
                    value={avgAttendees}
                    onChange={handleAvgAttendeesChange}
                  />
                </div>
              </div>
              <p className="revenue-amount display-4 text-white">${formatRevenue(calculateRevenue())}</p>
            </div>
          </div>
        </section>

        <section class="last-section">
          <h2 className="display-4 mb-0">Let's Partner</h2>
          <p style={{fontSize: '1.3rem'}} className="text-center mb-3">You bring the people <br className="d-block d-md-none"/>we connect them!</p>
          <a className='btn btn-circle btn-primary' href="https://calendly.com/andrewoodleyjr/30min" target="_blank" rel="noopener noreferrer">Schedule a Demo</a>
        </section>
      </div>
    </>
  );
};

export default Partner;
